import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@material-ui/core";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { IconButton } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import TextField from "@mui/material/TextField";
import Textarea from "@mui/joy/Textarea";
import { Auth } from "aws-amplify";
import { isAdminOnly, isMultipleOrganization } from "../../../Utils/ServerUtil";
import { IMSContext } from "../list/IMSformCategtorySelect";
import { IsTabClickedContext } from "./AssetPropertiesDrawer.js";
import get from "lodash/get";
import { saveIMSForms } from "../../../Utils/SubmitUtil.js";
import { IMSFormContext } from "./IMSFormContext.js";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ContentLoader from "react-content-loader";
import Stack from "@mui/material/Stack";
import "react-image-lightbox/style.css";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { IMS_FORM_IMG_PLACEHOLDER } from "../../../Constants";
import InfoIcon from "@mui/icons-material/Info";
import CheckIcon from "@mui/icons-material/Check";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";

const titleStyle = {
  fontSize: "0.8rem",
  marginBottom: "4px",
  color: "#8f8c8c",
};

const internaUseValues = {
  marginTop: "4px",
};

const internaUseValues2 = {
  marginBottom: "20px",
};

function IMSForm({ selectedCategory, evalItem, evaluation }) {
  const [formData, setFormData] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [nominationValue, setNominationValue] = useState("0");
  const [fieldValues, setFieldValues] = useState({});
  const { imsForm, setImsForm } = useContext(IMSFormContext);
  const [newIMSData, setNewIMSData] = useState(null);
  const [isCategoryChanged, setIsCategoryChanged] = useState(false);
  const [opportunityID, setOpportunityID] = useState({});
  const { jobCodeData, setJobCodeData } = useContext(IMSContext);
  const { loadForm, saveForm, setSaveForm } = useContext(IsTabClickedContext);
  const [successAlert, setSuccessAlert] = useState(false);
  const [lightboxImage, setLightboxImage] = useState(null);
  const [lightboxOpen, setLightboxOpen] = useState(false);

  const isMounted = useRef(false);

  const handleSaveSuccess = () => {
    setSuccessAlert(true);
  };

  const handleSaveSuccessClose = () => {
    setSuccessAlert(false);
  };

  const handleNominationChange = (event) => {
    const newValue = event.target.value.toString();
    setNominationValue(newValue);
  };

  useEffect(() => {
    if (selectedCategory && selectedCategory.id) {
      // Set isCategoryChanged to true when selectedCategory changes
      setIsCategoryChanged(true);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (
      imsForm &&
      imsForm?.item?.ims_forms_data &&
      imsForm?.item?.ims_forms_data.nomination
    ) {
      const nominationStringValue =
        imsForm?.item?.ims_forms_data.nomination.toString();
      setNominationValue(nominationStringValue);
    } else if (
      evalItem &&
      evalItem.item &&
      evalItem.item.ims_forms_data &&
      evalItem.item.ims_forms_data.nomination
    ) {
      const nominationStringValue =
        evalItem.item.ims_forms_data.nomination.toString();
      setNominationValue(nominationStringValue);
    }
  }, [imsForm, evalItem]);

  const handleFieldChange = (
    groupId,
    componentId,
    newValue,
    datatype,
    photoCapture = 0
  ) => {
    let formattedValue;

    if (photoCapture === 1) {
      // If the component is a photo capture, store both value and photo fields
      formattedValue = { value: newValue, photo: "" };
    } else {
      // Handle other datatypes
      switch (datatype) {
        case "boolean":
          formattedValue = newValue === true || newValue === "true";
          break;
        case "number":
          formattedValue = Number(newValue);
          break;
        default:
          formattedValue = newValue;
      }
    }
    // Set the formattedValue
    const updatedFieldValues = { ...fieldValues };
    updatedFieldValues[componentId] = formattedValue;
    setFieldValues(updatedFieldValues);
  };

  const getComponentResponseValue = (component) => {
    if (component.photo_capture === 1) {
      return component.component_response?.value || "";
    }

    switch (component.datatype) {
      case "boolean":
        return (
          component.component_response === "true" ||
          component.component_response === true
        );
      case "number":
        return Number(component.component_response) || 0;
      default:
        return component.component_response || "";
    }
  };

  console.log("-------------EVAL DATA TEST---------------", evaluation);

  useEffect(() => {
    const initialValues = {};

    const populateInitialValues = (groups) => {
      groups.forEach((group) => {
        group.components.forEach((component) => {
          if (component.control_type === "checkbox") {
            // Ensure it is a boolean (true/false)
            initialValues[component.id] =
              component.component_response === true ||
              component.component_response === "true";
          } else if (component.control_type === "checkboxgroup") {
            // Handle checkbox groups
            component.values.forEach((value) => {
              initialValues[value.id] =
                value.component_response === true ||
                value.component_response === "true";
            });
          } else {
            // Handle other control types
            initialValues[component.id] = component.component_response || "";
          }
        });
      });
    };

    if (imsForm?.item?.ims_forms_data?.groups) {
      populateInitialValues(imsForm.item.ims_forms_data.groups);
    } else {
      populateInitialValues(ims_forms_data.groups);
    }

    setFieldValues(initialValues);
  }, [imsForm]);

  const fetchIMSData = async () => {
    let jwtToken;
    let tokenRefreshError = false;

    try {
      const session = await Auth.currentSession();
      jwtToken = session.idToken.jwtToken;
    } catch (e) {
      try {
        const session = await Auth.currentSession();
        jwtToken = session.idToken.jwtToken;
      } catch (e) {
        //console.log("Error refreshing token", e);
        tokenRefreshError = true;
        // Allow to go through without jwtToken for server requests not requiring an authenticated user.
      }
    }

    const headers = {
      authorization: jwtToken,
      accept: "application/json",
    };
    // This adds a header for selected organization_ID or _Key which is stored in local storage
    if (isAdminOnly() || isMultipleOrganization()) {
      const organization = localStorage["hw." + window.btoa("organization")];
      if (organization && window.atob(organization)) {
        headers["HW-Organization"] = window.atob(organization);
      }
    }
    const options = {
      method: "GET",
      headers: headers,
    };

    const url = `${process.env.REACT_APP_API_ENDPOINT}/items/${evalItem.item_id}`;

    // console.log("url====> ",url);
    try {
      const response = await fetch(url, options);
      if (response.ok) {
        const itemData = await response.json();
        console.log("RETURNED ITEM ========>", itemData);

        setImsForm(itemData);
      } else {
        // Handle the error if the response is not ok
        console.log("Request failed with status:", response.status);
        return null;
      }
    } catch (error) {
      // Handle any network or other errors
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchIMSData();
  }, []);

  useEffect(() => {
    const opportunityDataFetch = async () => {
      let jwtToken;
      let tokenRefreshError = false;

      try {
        const session = await Auth.currentSession();
        jwtToken = session.idToken.jwtToken;
      } catch (e) {
        try {
          const session = await Auth.currentSession();
          jwtToken = session.idToken.jwtToken;
        } catch (e) {
          //console.log("Error refreshing token", e);
          tokenRefreshError = true;
          // Allow to go through without jwtToken for server requests not requiring an authenticated user.
        }
      }

      const headers = {
        authorization: jwtToken,
        accept: "application/json",
      };
      // This adds a header for selected organization_ID or _Key which is stored in local storage
      if (isAdminOnly() || isMultipleOrganization()) {
        const organization = localStorage["hw." + window.btoa("organization")];
        if (organization && window.atob(organization)) {
          headers["HW-Organization"] = window.atob(organization);
        }
      }
      const options = {
        method: "GET",
        headers: headers,
      };

      const url = `${process.env.REACT_APP_API_ENDPOINT}/evaluations/${evalItem?.item?.initial_eval}`;

      // console.log("url====> ",url);
      try {
        const response = await fetch(url, options);
        if (response.ok) {
          const data = await response.json();
          //   console.log(
          //     "INFORM OP ID ++++++++++++++++++++++++++++++++++>>>>>>>>>>>>>>>",
          //     data?.evaluation?.opportunity_id
          //   );

          setOpportunityID(data?.evaluation?.opportunity_id);
        } else {
          // Handle the error if the response is not ok
          console.log("Request failed with status:", response.status);
          return null;
        }
      } catch (error) {
        // Handle any network or other errors
        console.error("Error:", error);
      }
    };

    opportunityDataFetch();
    //handleSaveSuccess()
  }, []);

  useEffect(() => {
    if (imsForm && imsForm?.jobcode) {
      setJobCodeData(imsForm?.jobcode);
    } else if (evalItem && evalItem?.jobcode) {
      setJobCodeData(evalItem?.jobcode);
    }
  }, [imsForm, evalItem]);

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedCategory || !selectedCategory.id) return;

      try {
        setLoading(true);
        const response = await fetch(
          `https://ims-api.purplewave.com/v1/forms/category/${selectedCategory.id}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log("Form data received:", data);
        setFormData(data);

        setError(null);
      } catch (error) {
        console.error("Error fetching form data:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedCategory, evalItem]);

  // Create a new object to store the sorted components
  const newData = { ...formData };

  // Check if Categories array exists and is an array
  if (formData.Categories && Array.isArray(formData.Categories)) {
    // Iterate through categories to sort components
    newData.Categories.forEach((category) => {
      // Sort components by id
      category.Components.sort((a, b) => a.id - b.id);

      if (category.Groups && Array.isArray(category.Groups)) {
        category.Groups.forEach((group) => {
          // Sort components by order_id
          group.Components.sort((a, b) => a.order - b.order);

          // Filter components based on the category components
          if (group.Components && Array.isArray(group.Components)) {
            group.Components = group.Components.filter((component) => {
              return category.Components.some((categoryComponent) => {
                return (
                  component.group_id === categoryComponent.group_id &&
                  component.id === categoryComponent.id
                );
              });
            });
          }
        });
      }
    });
  } else {
    console.error("Categories array is missing or not an array.");
  }

  // Initialize the structure variable
  const ims_forms_data = {
    id: 0,
    groups: [],
    category: selectedCategory?.category,
    nomination: "0",
    opportunity_id: opportunityID != "" ? opportunityID : "",
    jobcode: "",
  };

  // Update the structure based on the received form data
  if (formData.id) {
    ims_forms_data.id = selectedCategory?.id;
    ims_forms_data.nomination = nominationValue;
    ims_forms_data.jobcode = jobCodeData;

    formData.Categories.forEach((category) => {
      const categoryObj = {
        id: category.id || 0,
        title: category.category || "",
        groups: [],
      };

      category.Groups.forEach((group) => {
        const groupObj = {
          id: group.id || 0,
          title: group.title || "",
          components: group.Components?.map((component) => {
            let componentResponse = component.component_response || "";
            if (
              component.photo_capture === 1
            ) {
              componentResponse = {
                value: component.component_response?.value || "",
                photo: component.component_response?.photo || "",
              };
            } else {
              componentResponse = "";
            }
            return {
              id: component.id || 0,
              label: component.label || "",
              label_display: component.label_display || "",
              limit: component.limit || 0,
              order: component.order || 0,
              values:
                component.Values?.map((value) => ({
                  id: value.id || 0,
                  order: value.order || 0,
                  value: value.value || "",
                  component_id: value.component_id || 0,
                  component_response: value.component_response || "",
                })) || [],
              prefix: component.prefix || "",
              suffix: component.suffix || "",
              datatype: component.datatype || "",
              group_id: component.group_id || 0,
              required: component.required || "",
              max_value: component.max_value || 0,
              min_value: component.min_value || 0,
              char_count: component.char_count || 0,
              max_length: component.max_length || 0,
              min_length: component.min_length || 0,
              helper_text: component.helper_text || "",
              control_type: component.control_type || "",
              photo_capture: component.photo_capture || 0,
              component_form: component.component_form || [],
              barcode_scanning: component.barcode_scanning || 0,
              component_response: componentResponse,
            };
          }),
        };
        categoryObj.groups.push(groupObj); // Add each group directly to the groups array under this category
      });

      ims_forms_data.groups.push(...categoryObj.groups); // Add all groups from this category to the top-level groups array
    });
  }

  // Create a ref for storing the previous evalItem
  const prevEvalItemRef = useRef(null);

  // Update prevEvalItemRef when evalItem changes
  useEffect(() => {
    prevEvalItemRef.current =
      imsForm?.item?.ims_forms_data &&
      Object.keys(imsForm?.item?.ims_forms_data).length > 0
        ? imsForm
        : evalItem;
  }, [evalItem, imsForm]);

  // Access the previous value of evalItem using prevEvalItemRef.current
  const prevEvalItem = prevEvalItemRef.current;

  const handleIMSFormSave = async () => {
    let updatedEvalItem;

    if (saveForm) {
      updatedEvalItem = { ...imsForm?.item };

      if (formData.id) {
        updatedEvalItem.ims_forms_data = ims_forms_data;
        updatedEvalItem.jobcode = ims_forms_data.jobcode;
        updatedEvalItem.sticker_id = imsForm.item.sticker_id;
      } else {
        updatedEvalItem.ims_forms_data = {};
      }

      // Update formData with field values
      const updatedFormData = { ...formData };
      updatedFormData.Categories.forEach((category) => {
        category.Groups.forEach((group) => {
          group.Components.forEach((component) => {
            // Apply datatype-specific conversion for component_response
            if (fieldValues[component.id] !== undefined) {
              component.component_response = convertToDataType(
                fieldValues[component.id],
                component.datatype
              );
            } else {
              component.Values.forEach((value) => {
                if (fieldValues[value.id] !== undefined) {
                  value.component_response = convertToDataType(
                    fieldValues[value.id],
                    component.datatype
                  );
                }
              });
            }
          });
        });
      });

      // Update ims_forms_data with field values
      ims_forms_data.groups.forEach((group) => {
        group.components.forEach((component) => {
          if (fieldValues[component.id] !== undefined) {
            component.component_response = convertToDataType(
              fieldValues[component.id],
              component.datatype
            );
          } else {
            component.values.forEach((value) => {
              if (fieldValues[value.id] !== undefined) {
                value.component_response = convertToDataType(
                  fieldValues[value.id],
                  component.datatype
                );
              }
            });
          }
        });
      });
    }

    const itemId = get(evalItem, "item_id", get(evalItem, "PK"));

    try {
      await saveIMSForms(
        itemId,
        updatedEvalItem,
        prevEvalItem,
        setSaveForm,
        saveForm,
        successAlert,
        handleSaveSuccess
      );
    } catch (e) {
      console.log("Error:", e);
    }
  };

  const convertToDataType = (value, datatype) => {
    switch (datatype) {
      case "boolean":
        return value === true || value === "true"; // Handle string 'true' or boolean true
      case "number":
        return Number(value); // Ensure it converts to a number
      default:
        return value; // Default case: convert to string
    }
  };

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    if (saveForm) {
      handleIMSFormSave();
      setSaveForm(false);
    }
  }, [saveForm]);

  useEffect(() => {
    ///console.log("Test IMSFORM===================>>>>>>>>>>>:", imsForm);
    const new_ims_forms_data = { ...imsForm?.item?.ims_forms_data };

    // Iterate over each group and their components
    new_ims_forms_data.groups = new_ims_forms_data?.groups?.map((group) => {
      group.components = group.components.map((component) => {
        // Check if the component meets the criteria
        if (
          component.control_type === "subheading" &&
          component.photo_capture === 1
        ) {
          // Check if component_response is an object
          if (
            component.component_response &&
            typeof component.component_response === "object"
          ) {
            // Copy the photo key from component_response to the component itself
            component.photo = component.component_response.photo;
          }
        }
        return component;
      });
      return group;
    });

    setNewIMSData(new_ims_forms_data);
  }, [imsForm, loadForm]);

  if (!loadForm && selectedCategory === null) {
    return (
      <div
        style={{
          padding: "20px",
          border: "1px solid #ccc",
          borderRadius: "5px",
          background: "#f9f9f9",
          maxHeight: "95%",
          overflowY: "auto",
          marginLeft: "20px",
          marginRight: "20px",
        }}
      >
        <div>
          <Snackbar
            open={successAlert}
            autoHideDuration={6000}
            onClose={handleSaveSuccessClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              onClose={handleSaveSuccessClose}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              IMS form is saved successfully!
            </Alert>
          </Snackbar>
        </div>

        <h2>LOAD FORM FOR PREVIEW</h2>
      </div>
    );
  } else if (
    !imsForm?.item?.ims_forms_data?.id ||
    Object.keys(imsForm?.item?.ims_forms_data).length === 0 ||
    selectedCategory
  ) {
    return (
      <div
        style={{
          padding: "20px",
          border: "1px solid #ccc",
          borderRadius: "5px",
          background: "#f9f9f9",
          maxHeight: "95%",
          overflowY: "auto",
          marginLeft: "20px",
          marginRight: "20px",
        }}
      >
        <div>
          <Snackbar
            open={successAlert}
            autoHideDuration={6000}
            onClose={handleSaveSuccessClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              onClose={handleSaveSuccessClose}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              IMS form is saved successfully!
            </Alert>
          </Snackbar>
        </div>
        {loading ? (
          <ContentLoader
            speed={2}
            width={400}
            height={150}
            viewBox="0 0 400 150"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="35" y="10" rx="5" ry="5" width="150" height="10" />
            <rect x="35" y="45" rx="5" ry="5" width="150" height="10" />
            <rect x="35" y="80" rx="5" ry="5" width="150" height="10" />
            <rect x="35" y="115" rx="5" ry="5" width="150" height="10" />
            <rect x="3" y="5" rx="4" ry="4" width="20" height="20" />
            <rect x="3" y="40" rx="4" ry="4" width="20" height="20" />
            <rect x="3" y="75" rx="4" ry="4" width="20" height="20" />
            <rect x="3" y="110" rx="4" ry="4" width="20" height="20" />
          </ContentLoader>
        ) : error ? (
          <div>Error: {error}</div>
        ) : (
          <div>
            <div key={ims_forms_data.id} style={{ marginBottom: "20px" }}>
              <div>
                <Typography style={titleStyle}>IMS Form Category</Typography>
                <h2>{ims_forms_data?.category}</h2>
                <div
                  style={{
                    margin: "20px 0",
                  }}
                >
                  <Divider />
                </div>
                <Grid container spacing={3} alignItems="center">
                  <Grid container item xs={6} direction="column">
                    <Grid item>
                      <Typography style={titleStyle}>Fee Code</Typography>
                      <h4 style={internaUseValues}>
                        {evaluation?.evaluation?.fee_code || "--------"}
                      </h4>
                    </Grid>
                    <Grid item>
                      <Typography style={titleStyle}>Location</Typography>
                      <h4 style={internaUseValues}>
                        {imsForm?.item?.location || "--------"}
                      </h4>
                    </Grid>
                    <Grid item>
                      <Typography style={titleStyle}>DC Service</Typography>
                      <h4 style={internaUseValues}>
                        {evaluation?.evaluation?.primary_contact || "--------"}
                      </h4>
                    </Grid>
                    <Grid item>
                      <Typography style={titleStyle}>
                        Copart Lot Number
                      </Typography>
                      <h4 style={internaUseValues}>
                        {imsForm?.lot_number || "--------"}
                      </h4>
                    </Grid>
                  </Grid>
                  <Grid container item xs={6} direction="column">
                    <Grid item>
                      <Typography style={titleStyle}>Form Status</Typography>
                      <div style={internaUseValues2}>
                        {imsForm?.item?.form_sub ? (
                          <Chip
                            color="success"
                            icon={<CheckIcon />}
                            label="Form Submited to IMS"
                            size="small"
                          />
                        ) : (
                          <Chip
                            icon={<InfoIcon />}
                            label="Form is not Submited"
                            size="small"
                          />
                        )}
                      </div>
                    </Grid>
                    <Grid item>
                      <Typography style={titleStyle}>Opportunity</Typography>
                      <h4 style={internaUseValues}>
                        {imsForm?.item?.ims_forms_data?.opportunity_id ||
                          "--------"}
                      </h4>
                    </Grid>
                    <Grid item>
                      <Typography style={titleStyle}>Job Code</Typography>
                      <h4 style={internaUseValues}>
                        {imsForm?.jobcode || "--------"}
                      </h4>
                    </Grid>
                    <Grid item>
                      <Typography style={titleStyle}>Nomination</Typography>
                      <FormControl
                        style={{ position: "relative", width: "100%" }}
                      >
                        <Select
                          labelId="nomination-label"
                          id="nomination-select"
                          value={nominationValue}
                          onChange={handleNominationChange}
                          style={{
                            width: "70%",
                            display: "flex",
                            alignItems: "center",
                          }}
                          MenuProps={{
                            PaperProps: { style: { maxHeight: 200 } },
                          }}
                        >
                          {[...Array(11)].map((_, index) => (
                            <MenuItem key={index} value={index}>
                              {index}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </div>

              <div style={{ margin: "12px 0" }}></div>

              <div style={{ maxHeight: "650px", overflowY: "auto" }}>
                {ims_forms_data.groups?.map((group) => (
                  <Accordion
                    key={group.id}
                    style={{
                      marginBottom: "10px",
                      background: "#fff",
                      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ArrowDownwardIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography style={{ color: "#4f2682" }}>
                        <h3>{group?.display_name || group.title}</h3>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ padding: "10px", background: "#f9f9f9" }}
                    >
                      <form>
                        {group?.components?.map((component) => (
                          <div
                            key={component.id}
                            style={{
                              marginBottom: "25px",
                              width: "100%",
                              marginLeft: "20px",
                            }}
                          >
                            {component.control_type === "checkbox" ? (
                              <>
                                {/* Log the value of fieldValues[component.id] */}
                                {console.log(
                                  "Checkbox value for component ID",
                                  component.id,
                                  ":",
                                  fieldValues[component.id]
                                )}

                                <input
                                  type="checkbox"
                                  style={{ marginRight: "5px" }}
                                  checked={
                                    fieldValues[component.id] === true ||
                                    fieldValues[component.id] === "true"
                                  }
                                  onChange={(event) =>
                                    handleFieldChange(
                                      group.id,
                                      component.id,
                                      event.target.checked,
                                      component.datatype
                                    )
                                  }
                                />
                              </>
                            ) : null}
                            {component.control_type !== "textfield" &&
                              component.control_type !== "checkboxgroup" &&
                              component.control_type !== "heading" &&
                              component.control_type !== "subheading" && (
                                <label style={{ marginBottom: "12px" }}>
                                  {component.label_display || component.label}
                                </label>
                              )}
                            {component.control_type === "textfield" ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  mmaxWidth: "80%",
                                }}
                              >
                                {component.prefix && (
                                  <span style={{ marginRight: "5px" }}>
                                    {component.prefix}
                                  </span>
                                )}
                                <TextField
                                  id={component.id}
                                  variant="standard"
                                  sx={{ width: "300px" }}
                                  label={
                                    component.label_display || component.label
                                  }
                                  // Check if the value is an object and then access the `value` field
                                  value={
                                    typeof fieldValues[component.id] ===
                                    "object"
                                      ? fieldValues[component.id]?.value || ""
                                      : fieldValues[component.id] || ""
                                  }
                                  onChange={(event) =>
                                    handleFieldChange(
                                      group.id,
                                      component.id,
                                      event.target.value,
                                      component.datatype
                                    )
                                  }
                                />

                                {component.suffix && (
                                  <span style={{ marginLeft: "5px" }}>
                                    {component.suffix}
                                  </span>
                                )}
                              </div>
                            ) : component.control_type === "numberfield" ? (
                              <input
                                type="number"
                                style={{
                                  width: "100%",
                                  padding: "5px",
                                  border: "1px solid #ccc",
                                  borderRadius: "3px",
                                  margin: "10px 0",
                                }}
                                value={component.component_response || ""}
                                onChange={(event) =>
                                  handleFieldChange(
                                    group.id,
                                    component.id,
                                    event.target.value
                                  )
                                }
                              />
                            ) : component.control_type === "textarea" ? (
                              // <textarea style={{ width: '100%', padding: '5px', border: '1px solid #ccc', borderRadius: '3px', margin: '10px 0' }} />
                              <Textarea
                                id={component.id}
                                minRows={2}
                                size="md"
                                style={{
                                  padding: "5px",
                                  border: "1px solid #ccc",
                                  borderRadius: "3px",
                                  maxWidth: "300px",
                                }}
                                value={fieldValues[component.id] || ""}
                                onChange={(event) =>
                                  handleFieldChange(
                                    group.id,
                                    component.id,
                                    event.target.value
                                  )
                                }
                              />
                            ) : component.control_type === "select" ? (
                              <select
                                style={{
                                  width: "100%",
                                  padding: "5px",
                                  border: "1px solid #ccc",
                                  borderRadius: "3px",
                                  margin: "10px 0",
                                }}
                              >
                                {component.values?.map((option) => (
                                  <option
                                    key={option.id}
                                    value={option.component_response || ""}
                                    onChange={(event) =>
                                      handleFieldChange(
                                        group.id,
                                        component.id,
                                        event.target.value
                                      )
                                    }
                                  >
                                    {option.value}
                                  </option>
                                ))}
                              </select>
                            ) : component.control_type === "checkboxgroup" ? (
                              <div
                                style={{
                                  marginTop: "30px",
                                  marginBottom: "30px",
                                }}
                              >
                                <div style={{ marginBottom: "5px" }}>
                                  <label style={{ margin: "0" }}>
                                    {component.label_display || component.label}
                                  </label>
                                </div>
                                <Grid container alignItems="center">
                                  {component.values?.map((option) => (
                                    <div key={option.id}>
                                      {console.log(
                                        "CheckboxGroup value for option ID",
                                        option.id,
                                        ":",
                                        fieldValues[option.id]
                                      )}
                                      <Grid container alignItems="center">
                                        <input
                                          type="checkbox"
                                          id={option.id}
                                          // Ensure the value is checked when true or "true"
                                          checked={
                                            fieldValues[option.id] === true ||
                                            fieldValues[option.id] === "true"
                                          }
                                          style={{
                                            color: "#4f2682",
                                            marginLeft: "5px",
                                          }}
                                          onChange={(event) =>
                                            handleFieldChange(
                                              group.id,
                                              option.id,
                                              event.target.checked,
                                              component.datatype
                                            )
                                          }
                                        />
                                        <label
                                          htmlFor={option.value}
                                          style={{
                                            color: "#4f2682",
                                            marginLeft: "5px",
                                            marginBottom: "0",
                                          }}
                                        >
                                          {option.value}
                                        </label>
                                      </Grid>
                                    </div>
                                  ))}
                                </Grid>
                              </div>
                            ) : component.control_type === "subheading" ? (
                              <h4>
                                {component.label_display || component.label}
                              </h4>
                            ) : null}

                            {component.component_form &&
                              component.component_form.length > 0 && (
                                <IconButton
                                  aria-label="Help"
                                  onClick={() => {
                                    window.open(
                                      component.component_form[0].help_link,
                                      "_blank"
                                    );
                                  }}
                                >
                                  <OpenInNewIcon
                                    style={{
                                      fontSize: "15px",
                                      color: "#4f2682",
                                    }}
                                  />
                                </IconButton>
                              )}

                            {/* Render helper text */}
                            {component.helper_text && (
                              <div style={{ width: "70%" }}>
                                <Typography
                                  variant="caption"
                                  style={{ fontSize: "small", color: "#666" }}
                                >
                                  {component.helper_text}
                                </Typography>
                              </div>
                            )}
                          </div>
                        ))}
                      </form>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  } else if (
    imsForm?.item?.ims_forms_data?.id ||
    Object.keys(imsForm?.item?.ims_forms_data).length !== 0
  ) {
    return (
      <div
        style={{
          padding: "20px",
          border: "1px solid #ccc",
          borderRadius: "5px",
          background: "#f9f9f9",
          maxHeight: "95%",
          overflowY: "auto",
          marginLeft: "20px",
          marginRight: "20px",
        }}
      >
        <div>
          <Snackbar
            open={successAlert}
            autoHideDuration={6000}
            onClose={handleSaveSuccessClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              onClose={handleSaveSuccessClose}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              IMS form is saved successfully!
            </Alert>
          </Snackbar>
        </div>

        {loading ? (
          <ContentLoader
            speed={2}
            width={400}
            height={150}
            viewBox="0 0 400 150"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="35" y="10" rx="5" ry="5" width="150" height="10" />
            <rect x="35" y="45" rx="5" ry="5" width="150" height="10" />
            <rect x="35" y="80" rx="5" ry="5" width="150" height="10" />
            <rect x="35" y="115" rx="5" ry="5" width="150" height="10" />
            <rect x="3" y="5" rx="4" ry="4" width="20" height="20" />
            <rect x="3" y="40" rx="4" ry="4" width="20" height="20" />
            <rect x="3" y="75" rx="4" ry="4" width="20" height="20" />
            <rect x="3" y="110" rx="4" ry="4" width="20" height="20" />
          </ContentLoader>
        ) : error ? (
          <div>Error: {error}</div>
        ) : (
          <div>
            <div key={newIMSData?.id} style={{ marginBottom: "20px" }}>
              <div>
                <Typography style={titleStyle}>IMS Form Category</Typography>
                <h2>{newIMSData?.category}</h2>
                <div
                  style={{
                    backgroundColor: "#8f8c8c",
                    height: "1px",
                    margin: "20px 0",
                  }}
                ></div>
                <Grid container spacing={3} alignItems="center">
                  <Grid container item xs={6} direction="column">
                    <Grid item>
                      <Typography style={titleStyle}>Fee Code</Typography>
                      <h4 style={internaUseValues}>
                        {evaluation?.evaluation?.fee_code || "--------"}
                      </h4>
                    </Grid>
                    <Grid item>
                      <Typography style={titleStyle}>Location</Typography>
                      <h4 style={internaUseValues}>
                        {imsForm?.item?.location || "--------"}
                      </h4>
                    </Grid>
                    <Grid item>
                      <Typography style={titleStyle}>DC Service</Typography>
                      <h4 style={internaUseValues}>
                        {evaluation?.evaluation?.primary_contact || "--------"}
                      </h4>
                    </Grid>
                    <Grid item>
                      <Typography style={titleStyle}>
                        Copart Lot Number
                      </Typography>
                      <h4 style={internaUseValues}>
                        {imsForm?.lot_number || "--------"}
                      </h4>
                    </Grid>
                  </Grid>
                  <Grid container item xs={6} direction="column">
                    <Grid item>
                      <Typography style={titleStyle}>Form Status</Typography>
                      <h4 style={internaUseValues}>Sent to IMS</h4>
                    </Grid>
                    <Grid item>
                      <Typography style={titleStyle}>Opportunity</Typography>
                      <h4 style={internaUseValues}>
                        {imsForm?.item?.ims_forms_data?.opportunity_id ||
                          "--------"}
                      </h4>
                    </Grid>
                    <Grid item>
                      <Typography style={titleStyle}>Job Code</Typography>
                      <h4 style={internaUseValues}>
                        {imsForm?.jobcode || "--------"}
                      </h4>
                    </Grid>
                    <Grid item>
                      <Typography style={titleStyle}>Nomination</Typography>
                      <FormControl
                        style={{ position: "relative", width: "100%" }}
                      >
                        <Select
                          labelId="nomination-label"
                          id="nomination-select"
                          value={nominationValue}
                          onChange={handleNominationChange}
                          style={{
                            width: "70%",
                            display: "flex",
                            alignItems: "center",
                          }}
                          MenuProps={{
                            PaperProps: { style: { maxHeight: 200 } },
                          }}
                        >
                          {[...Array(11)].map((_, index) => (
                            <MenuItem key={index} value={index}>
                              {index}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <div style={{ margin: "12px 0" }}></div>

              <div style={{ maxHeight: "650px", overflowY: "auto" }}>
                {newIMSData?.groups?.map((group) => (
                  <Accordion
                    key={group.id}
                    style={{
                      marginBottom: "10px",
                      background: "#fff",
                      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ArrowDownwardIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography style={{ color: "#4f2682" }}>
                        <h3>{group?.display_name || group.title}</h3>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ padding: "10px", background: "#f9f9f9" }}
                    >
                      <form>
                        {group?.components?.map((component) => (
                          <div
                            key={component.id}
                            style={{
                              marginBottom: "25px",
                              width: "100%",
                              marginLeft: "20px",
                            }}
                          >
                            {component.control_type === "checkbox" ? (
                              <input
                                type="checkbox"
                                style={{ marginRight: "5px" }}
                                checked={fieldValues[component.id] || false}
                                onChange={(event) =>
                                  handleFieldChange(
                                    group.id,
                                    component.id,
                                    event.target.checked,
                                    component.datatype
                                  )
                                }
                              />
                            ) : null}
                            {component.control_type !== "textfield" &&
                              component.control_type !== "checkboxgroup" &&
                              component.control_type !== "heading" &&
                              component.control_type !== "subheading" && (
                                <label style={{ marginBottom: "12px" }}>
                                  {component.label_display || component.label}
                                </label>
                              )}
                            {component.control_type === "textfield" ? (
                              component.photo_capture === 1 ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    mmaxWidth: "80%",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  {component.prefix && (
                                    <span style={{ marginRight: "5px" }}>
                                      {component.prefix}
                                    </span>
                                  )}
                                  <TextField
                                    id={component.id}
                                    variant="standard"
                                    sx={{ width: "300px" }}
                                    label={
                                      component.label_display || component.label
                                    }
                                    // Check if the value is an object and then access the `value` field
                                    value={
                                      typeof fieldValues[component.id] ===
                                      "object"
                                        ? fieldValues[component.id]?.value || ""
                                        : fieldValues[component.id] || ""
                                    }
                                    onChange={(event) =>
                                      handleFieldChange(
                                        group.id,
                                        component.id,
                                        event.target.value,
                                        component.datatype
                                      )
                                    }
                                  />

                                  {component.suffix && (
                                    <span
                                      style={{
                                        marginLeft: "5px",
                                        fontSize: "2px ",
                                      }}
                                    >
                                      {component.suffix}
                                    </span>
                                  )}
                                  <Stack direction="row" spacing={2}>
                                    <img
                                      src={
                                        component.component_response?.photo ||
                                        IMS_FORM_IMG_PLACEHOLDER
                                      }
                                      alt="Thumbnail"
                                      style={{
                                        width: 56,
                                        height: 56,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setLightboxImage(
                                          component.component_response?.photo
                                        );
                                        setLightboxOpen(true);
                                      }}
                                    />
                                    {lightboxOpen && lightboxImage && (
                                      <Dialog
                                        open={lightboxOpen}
                                        onClose={() => setLightboxOpen(false)}
                                        maxWidth="lg"
                                        fullWidth
                                        style={{ margin: 0 }}
                                      >
                                        <DialogTitle>
                                          <IconButton
                                            edge="end"
                                            color="inherit"
                                            onClick={() =>
                                              setLightboxOpen(false)
                                            }
                                            aria-label="close"
                                          >
                                            <CloseIcon />
                                          </IconButton>
                                        </DialogTitle>
                                        <DialogContent style={{ padding: 0 }}>
                                          <img
                                            src={lightboxImage}
                                            alt="Full size"
                                            style={{
                                              width: "100%",
                                              height: "auto",
                                            }}
                                          />
                                        </DialogContent>
                                      </Dialog>
                                    )}
                                  </Stack>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    mmaxWidth: "80%",
                                  }}
                                >
                                  {component.prefix && (
                                    <span style={{ marginRight: "5px" }}>
                                      {component.prefix}
                                    </span>
                                  )}
                                  <TextField
                                    id={component.id}
                                    variant="standard"
                                    sx={{ width: "300px" }}
                                    label={
                                      component.label_display || component.label
                                    }
                                    value={fieldValues[component.id] || ""}
                                    onChange={(event) =>
                                      handleFieldChange(
                                        group.id,
                                        component.id,
                                        event.target.value,
                                        component.datatype
                                      )
                                    }
                                  />

                                  {component.suffix && (
                                    <span style={{ marginLeft: "5px" }}>
                                      {component.suffix}
                                    </span>
                                  )}
                                </div>
                              )
                            ) : component.control_type === "numberfield" ? (
                              <input
                                type="number"
                                style={{
                                  width: "100%",
                                  padding: "5px",
                                  border: "1px solid #ccc",
                                  borderRadius: "3px",
                                  margin: "10px 0",
                                }}
                                value={component.component_response || ""}
                                onChange={(event) =>
                                  handleFieldChange(
                                    group.id,
                                    component.id,
                                    event.target.value
                                  )
                                }
                              />
                            ) : component.control_type === "textarea" ? (
                              // <textarea style={{ width: '100%', padding: '5px', border: '1px solid #ccc', borderRadius: '3px', margin: '10px 0' }} />
                              <Textarea
                                id={component.id}
                                minRows={2}
                                size="md"
                                style={{
                                  padding: "5px",
                                  border: "1px solid #ccc",
                                  borderRadius: "3px",
                                  maxWidth: "300px",
                                }}
                                value={fieldValues[component.id] || ""}
                                onChange={(event) =>
                                  handleFieldChange(
                                    group.id,
                                    component.id,
                                    event.target.value
                                  )
                                }
                              />
                            ) : component.control_type === "select" ? (
                              <select
                                style={{
                                  width: "100%",
                                  padding: "5px",
                                  border: "1px solid #ccc",
                                  borderRadius: "3px",
                                  margin: "10px 0",
                                }}
                              >
                                {component.values?.map((option) => (
                                  <option
                                    key={option.id}
                                    value={option.component_response || ""}
                                    onChange={(event) =>
                                      handleFieldChange(
                                        group.id,
                                        component.id,
                                        event.target.value
                                      )
                                    }
                                  >
                                    {option.value}
                                  </option>
                                ))}
                              </select>
                            ) : component.control_type === "checkboxgroup" ? (
                              <div
                                style={{
                                  marginTop: "30px",
                                  marginBottom: "30px",
                                }}
                              >
                                <div style={{ marginBottom: "5px" }}>
                                  <label style={{ margin: "0" }}>
                                    {component.label_display || component.label}
                                  </label>
                                </div>
                                <Grid container alignItems="center">
                                  {component.values?.map((option) => (
                                    <div key={option.id}>
                                      <Grid container alignItems="center">
                                        {/* <input type="checkbox" id={option.id}  checked={fieldValues[option.id] || false}  style={{ color: '#4f2682', marginLeft: '5px' }} value={fieldValues[option.id] || 'true'}  onChange={(event) => handleFieldChange(group.id, option.id, event.target.checked ? 'true' : 'false')}/> */}
                                        <input
                                          type="checkbox"
                                          id={option.id}
                                          checked={
                                            fieldValues[option.id] || false
                                          } // Use fieldValues directly
                                          style={{
                                            color: "#4f2682",
                                            marginLeft: "5px",
                                          }}
                                          onChange={(event) =>
                                            handleFieldChange(
                                              group.id,
                                              option.id,
                                              event.target.checked
                                            )
                                          }
                                        />
                                        <label
                                          htmlFor={option.value}
                                          style={{
                                            color: "#4f2682",
                                            marginLeft: "5px",
                                            marginBottom: "0",
                                          }}
                                        >
                                          {option.value}
                                        </label>
                                      </Grid>
                                    </div>
                                  ))}
                                </Grid>
                              </div>
                            ) : component.control_type === "heading" ? (
                              <h3 style={{ color: "#4f2682" }}>
                                {component.label_display || component.label}
                              </h3>
                            ) : component.control_type === "subheading" ? (
                              component.photo_capture === 1 ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <h4>
                                    {component.label_display || component.label}
                                  </h4>
                                  <Stack direction="row" spacing={2}>
                                    <img
                                      src={
                                        component.component_response?.photo ||
                                        IMS_FORM_IMG_PLACEHOLDER
                                      }
                                      alt="Thumbnail"
                                      style={{
                                        width: 56,
                                        height: 56,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setLightboxImage(
                                          component.component_response?.photo
                                        );
                                        setLightboxOpen(true);
                                      }}
                                    />
                                    {lightboxOpen && lightboxImage && (
                                      <Dialog
                                        open={lightboxOpen}
                                        onClose={() => setLightboxOpen(false)}
                                        maxWidth="lg"
                                        fullWidth
                                        style={{ margin: 0 }}
                                      >
                                        <DialogTitle>
                                          <IconButton
                                            edge="end"
                                            color="inherit"
                                            onClick={() =>
                                              setLightboxOpen(false)
                                            }
                                            aria-label="close"
                                          >
                                            <CloseIcon />
                                          </IconButton>
                                        </DialogTitle>
                                        <DialogContent style={{ padding: 0 }}>
                                          <img
                                            src={lightboxImage}
                                            alt="Full size"
                                            style={{
                                              width: "100%",
                                              height: "auto",
                                            }}
                                          />
                                        </DialogContent>
                                      </Dialog>
                                    )}
                                  </Stack>
                                </div>
                              ) : (
                                <h4>
                                  {component.label_display || component.label}
                                </h4>
                              )
                            ) : null}

                            {component.component_form &&
                              component.component_form.length > 0 && (
                                <IconButton
                                  aria-label="Help"
                                  onClick={() => {
                                    window.open(
                                      component.component_form[0].help_link,
                                      "_blank"
                                    );
                                  }}
                                >
                                  <OpenInNewIcon
                                    style={{
                                      fontSize: "15px",
                                      color: "#4f2682",
                                    }}
                                  />
                                </IconButton>
                              )}

                            {/* Render helper text */}
                            {component.helper_text && (
                              <div style={{ width: "70%" }}>
                                <Typography
                                  variant="caption"
                                  style={{ fontSize: "small", color: "#666" }}
                                >
                                  {component.helper_text}
                                </Typography>
                              </div>
                            )}
                          </div>
                        ))}
                      </form>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default IMSForm;
